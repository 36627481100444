<template>
  <div class="analysis container">
    <FilterTest />
    <hr />
    <div class="row sat">
      <div class="col-md-5 sat_left print_div">
        <div>
          <p>ACT</p>
          <span><i class="fa fa-registered" aria-hidden="true"></i></span>
        </div>
      </div>
      <div class="col-md-7 sat_right row">
        <div class="col-md-8">
          <ul>
            <li>考卷: <b></b></li>
            <li v-if="teacherId > 0">
              老師:
            </li>
            <li v-if="dateArr !== null">
              日期: <b>{{ dateArr[0] }} ~ {{ dateArr[1] }}</b>
            </li>
          </ul>
        </div>
        <div class="col-md-4 img" style="line-height:93px;">
          <img
            src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/logo/logo.png"
          />
        </div>
      </div>
    </div>
    <div v-if="analysis">
      <ACTAnalysis :tests="tests" :analysis="analysis" :avgScore="user_score" />
    </div>
  </div>
</template>
<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Tests from "@/apis/tests.js";
import FilterTest from "@/views/analysis/Index";
import ACTAnalysis from "@/views/sessionClasses/analysis/components/ACT";

export default {
  metaInfo() {
    return {
      title: "Analysis - Detail - Ivy-Way Academy"
    };
  },

  components: {
    FilterTest,
    ACTAnalysis
  },

  mixins: [],

  props: [],
  data() {
    return {
      testType: null,
      test: null,
      dateArr: null,
      teacherId: null,
      showScores: false,
      showPer: true,
      progress: {
        progress_total_score: 0,
        progress_section_english_score: 0,
        progress_section_math_score: 0,
        progress_reading_score: 0,
        progress_math_score: 0,
        progress_aih_score: 0,
        progress_ais_score: 0,
        progress_coe_score: 0,
        progress_wic_score: 0,
        progress_eoi_score: 0,
        progress_hoa_score: 0,
        progress_psada_score: 0,
        progress_ptam_score: 0,
        progress_sec_score: 0,
        progress_essay_reading: 0,
        progress_essay_analysis: 0,
        progress_essay_writing: 0
      },
      progressType: 1,
      showAnswer: true,
      tests: [],
      analysis: null,
      user_score: {
        score: {}
      },
      sectionArr: {
        reading: "Reading",
        writing: "Writing",
        math: "Math",
        "math calculator": " Math Calculator <i class='fa fa-calculator'>"
      }
    };
  },
  computed: {},
  watch: {},

  mounted() {
    if (this.$route.query.type) {
      this.testType = this.$route.query.type;
    }
    if (this.$route.query.test) {
      this.test = this.$route.query.test;
    }
    if (this.$route.query.teacherId) {
      this.teacherId = parseInt(this.$route.query.teacherId);
    }
    if (this.$route.query.dateArr) {
      this.dateArr = this.$route.query.dateArr;
    }
    this.getAnalysis();
  },

  methods: {
    async getAnalysis() {
      let user_ids = null;
      if (this.teacherId > 0) {
        await Tests.getTeacherStudents({
          teacher_user_id:this.teacherId
        });
      }
      const res = await Tests.getActAnalysis({
        act_id: this.test,
        user_ids: user_ids,
        is_finished: 1,
        date_start: this.dateArr !== null ? this.dateArr[0] : null,
        date_end: this.dateArr !== null ? this.dateArr[1] : null
      });
      this.analysis = {
        scores: {},
        answers: {}
      };
      this.analysis["scores"] = res.user_exams;
      this.analysis["scores"].forEach(element => {
        element["last"] = null;
        element["category"] = "Act";
      });
      this.analysis["answers"] = res.act_questions;
      Object.keys(this.analysis["answers"]).forEach(index => {
        this.analysis["answers"][index].forEach(element => {
          if (element.answers[0].answers === undefined) {
            element.answers[0].answers = [];
          }
          element.answers = [element.answers[0].answers];
          element["total"] = element.exam_count;
          element["correct"] = element.correct_count;
          element["wrong"] = element["answer_count"] - element["correct"];
          element["omit"] = element["total"] - element["answer_count"];
          element["student_answers"] = {};
        });
      });
      this.user_score.score.total_avg_score = res.avg_scores[0].total_avg_score;
      this.user_score.score.english_avg_score =
        res.avg_scores[0].english_avg_score;
      this.user_score.score.math_avg_score = res.avg_scores[0].math_avg_score;
      this.user_score.score.reading_avg_score =
        res.avg_scores[0].reading_avg_score;
      this.user_score.score.science_avg_score =
        res.avg_scores[0].science_avg_score;
    }
  }
};
</script>

<style scoped>
hr {
  border-top: 2px solid #42a16a;
}
.analysis {
  padding: 20px;
}
.title {
  font-size: 30px;
}
.total p,
.total ul {
  margin: 0px;
}

.total {
  margin-top: 30px;
}
.total .title {
  font-size: 30px;
  color: #43a06b;
  margin-bottom: 20px;
}
.total_under {
  padding: 0px;
}
.total_above .score {
  font-size: 60px;
  color: #222;
  font-weight: 700;
  line-height: 70px;
  height: 70px;
}
.total .score + p {
  margin-top: 25px;
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  border-left: 4px solid #ccc;
}
.sat_left > div > span {
  display: inline-block;
  position: absolute;
  left: 290px;
  top: 28px;
}
.sat_left > div > p:first-child {
  font-size: 140px;
  font-weight: 700;
  color: #43a06b;
  line-height: 140px;
  margin-bottom: 0px;
}
.sat_right {
  padding-left: 0px;
  padding-top: 30px;
  border-bottom: 1px solid#DCDFE6;
}
.sat_right ul {
  clear: both;
}
.sat_right ul li {
  font-size: 20px;
  line-height: 30px;
}
.sat_right img {
  width: 90%;
}
</style>
